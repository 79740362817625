import React, {Component} from 'react';
import {gpn} from "../GPN"
import Icon from "../sections/Icon";
import Header from "../sections/Header";
import axios from "axios";
import config from "../config";
import Footer from "../sections/Footer";

class Home extends Component {

    state = {
        search: "",
        loading: false,

        title: "",
        generic: "",
        manufacturers: [],
        data: null,

        status: "none"
    };

    constructor() {
        super();
        this.SearchRef = React.createRef();
    }

    componentDidMount() {
        document.title = "دارویاب | خانه"
    }

    search() {
        const {search} = this.state;
        if (search.length > 0) {
            this.setState({
                loading: true
            });
            axios.get(`${config.api}/search?search=${search}&license=${config.license}`)
                .then(r => {
                    const {result} = r.data;
                    if (result) {
                        let {title, generic, manufacturers, url} = result;
                        manufacturers = manufacturers ? manufacturers : [];
                        title = title ? title : "";
                        generic = generic ? generic : "";
                        if (url !== "" && title !== "") {
                            this.setState({
                                title,
                                generic,
                                manufacturers,
                            });
                            this.info(url);
                        } else {
                            this.setState({status: "404", loading: false});
                        }
                    } else {
                        this.setState({status: "404", loading: false});
                    }
                })
                .catch(err => {
                    this.setState({
                        status: "error"
                    });
                });
        }
    }

    info(url = "") {
        axios.get(`${config.api}/info?url=${url}&license=${config.license}`)
            .then(r => {
                const {result} = r.data;
                if (result) {
                    this.setState({data: result, status: "200", loading: false});
                } else {
                    this.setState({status: "404", loading: false});
                }
            })
            .catch(err => {
                this.setState({
                    status: "error", loading: false
                });
            });
    }

    render() {
        const {search, loading, status, title, generic, manufacturers, data} = this.state;
        return (
            <>

                <Header title="خانه"/>
                <main className="home">
                    <div className="buttons">
                        <div className="row">
                            <div className="col-md-4">
                                <a href="https://mosbatesabz.com/" target="_blank">داروخانه آنلاین مثبت سبز</a>
                            </div>
                            <div className="col-md-4">
                                <a href="https://darukade.com/" target="_blank">داروخانه آنلاین داروکده</a>
                            </div>
                            <div className="col-md-4">
                                <a href="https://zeevash.ir/" target="_blank">داروخانه آنلاین زیوش</a>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">

                        <div className="col-xl-4 col-lg-5 col-md-6">
                            <form onSubmit={e => {
                                e.preventDefault();
                                this.search()
                            }} autoComplete="off">
                                <section className="search">

                                    <input type="text"
                                           ref={this.SearchRef}
                                           name="search"
                                           id="search"
                                           value={search}
                                           onChange={(e) => {
                                               const {name, value} = e.target;
                                               this.setState({[name]: value})
                                           }}
                                           placeholder="نام دارو ..."/>

                                    <Icon i="search" className="search-icon" solid onClick={() => {
                                        this.SearchRef.current.focus();
                                        this.search()
                                    }}/>
                                </section>
                            </form>

                        </div>
                    </div>

                    {loading ?
                        <section className="loading">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <div className="loader"></div>
                                </div>
                                <div className="col-auto">
                                    <div className="loading-text">در حال جستجوی داروی {search} ...</div>
                                </div>
                            </div>
                        </section> :
                        status === "404" ?
                            <div className="not-found">دارویی به این نام پیدا نشد!</div>
                            :
                            status === "error" ?
                                <div className="error">خطایی رخ داده است!</div>
                                : status === "200" ?
                                    <section className="info">
                                        <h2>
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <div className="title-fa">{title}</div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="title-en">{generic}</div>
                                                </div>
                                            </div>
                                        </h2>
                                        <div className="manufacturers">
                                            <h3>تولیدکنندگان:</h3>
                                            {
                                                manufacturers?.map((m, i) =>
                                                    <article key={"m" + i}>
                                                        -&nbsp;{m}
                                                    </article>
                                                )
                                            }
                                        </div>
                                        <br/>
                                        {this.renderData(data)}
                                    </section>
                                    : null
                    }
                </main>
                <Footer/>
            </>
        );
    }

    renderData(data) {
        if (data) {
            const {title, useInPregnancy, extras, information, group, faNames, enNames} = data;
            return (
                <>
                    <div className="box">
                        <h3>مصرف در بارداری:</h3>
                        <section className="box-data">
                            <pre>{useInPregnancy}</pre>
                        </section>
                    </div>
                    {
                        extras.map((ex, i) =>
                            <div className="box" key={"ex" + i}>
                                <h3>{ex.title}:</h3>
                                <section className="box-data">
                                    <pre>{ex.value}</pre>
                                </section>
                            </div>
                        )
                    }
                    {
                        information.map((inf, i) =>
                            <div className="box" key={"inf" + i}>
                                <h3>{inf.title}:</h3>
                                <section className="box-data">
                                    <pre>{inf.value}</pre>
                                </section>
                            </div>
                        )
                    }
                    <div className="box">
                        <h3>داروهای هم گروه&nbsp;{title}:</h3>
                        <section className="box-data">
                            {group.map((g, i) =>
                                <article className="group" key={"gr" + i}>-&nbsp;{g}</article>
                            )
                            }
                        </section>
                    </div>
                    <div className="box">
                        <h3>اسامی تجاری فارسی:</h3>

                        <section className="box-data">
                            <div className="names">
                                {faNames.map((fa, i) =>
                                    <article className="name" key={"fa" + i}>
                                        <p><span>برند:</span><span>{fa.brand}</span></p>
                                        <p><span>تولید کننده:</span><span>{fa.manufacturer}</span></p>
                                        <p>
                                            <span>وارد کننده:</span><span>{fa.importer !== "" ? fa.importer : "نامشخص"}</span>
                                        </p>
                                    </article>
                                )}
                            </div>
                        </section>
                    </div>
                    <div className="box">
                        <h3>اسامی تجاری انگلیسی:</h3>

                        <section className="box-data">
                            <div className="names">
                                {enNames.map((en, i) =>
                                    <article className="name" key={"en" + i}>
                                        <p><span>برند:</span><span>{en.brand}</span></p>
                                        <p><span>تولید کننده:</span><span>{en.manufacturer}</span></p>
                                        <p>
                                            <span>وارد کننده:</span><span>{en.importer !== "" ? en.importer : "نامشخص"}</span>
                                        </p>
                                    </article>
                                )}
                            </div>
                        </section>
                    </div>
                </>
            )
        } else {
            return null;
        }

    }
}

export default gpn(Home);