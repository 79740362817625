import React, {Component} from 'react';
import {gpn} from "../GPN"
import Header from "../sections/Header";
import config from "../config";
import Icon from "../sections/Icon";
import Footer from "../sections/Footer";
import {Link} from "react-router-dom";

class Articles extends Component {

    state = {
        title: "",
        content: "",
        file: null,
        articles: []
    };

    componentDidMount() {
        document.title = "دارویاب | ثبت دارو";
        this.getArticles();
    }

    getArticles() {
        let articles = [];
        for (let i = 0, len = localStorage.length; i < len; ++i) {
            if (localStorage.key(i).includes("article-")) {
                try {
                    let d = JSON.parse(localStorage.getItem(localStorage.key(i)));
                    d = {
                        ...d, key: localStorage.key(i)
                    }
                    articles.push(d);
                } catch (err) {
                }
            }
        }
        articles = articles.reverse();

        this.setState({articles});
    }

    getBase64(file) {
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                resolve("")
            };
        })

    }

    save() {
        const {title, file, content} = this.state;
        if (title.length !== 0 && file && content.length !== 0) {
            this.getBase64(file)
                .then(b64 => {
                    const key = `article-${config.random(1000000000, 100000000000)}`
                    localStorage.setItem(key, JSON.stringify({
                        title,
                        content,
                        image: b64,
                        key
                    }));
                    this.setState({
                        title: "",
                        content: "",
                        file: null
                    });
                    this.getArticles();
                });

        } else {
            alert("فیلد عکس یا عنوان یا توضیحات دارو وارد نشده!");
        }
    }

    delete(key) {
        try {
            localStorage.removeItem(key);
            this.getArticles();
        } catch (err) {

        }
    }

    render() {
        const {articles, title, content} = this.state;
        return (<>
            <Header title="ثبت دارو"/>
            <main className="articles">
                <div className="new">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-control">
                                <label htmlFor="title">نام دارو:</label>
                                <input type="text"
                                       name="title"
                                       id="title"
                                       autoComplete="off"
                                       placeholder="نام دارو را وارد کنید ..."
                                       onChange={e => {
                                           const {name, value} = e.target;
                                           this.setState({[name]: value})
                                       }}
                                       value={title}/>
                            </div>
                            <div className="form-control">
                                <label htmlFor="content">توضیحات دارو:</label>
                                <textarea
                                    rows={5}
                                    name="content"
                                    id="content"
                                    autoComplete="off"
                                    placeholder="توضیحات دارو را وارد کنید ..."
                                    onChange={e => {
                                        const {name, value} = e.target;
                                        this.setState({[name]: value})
                                    }}
                                    value={content}/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-control">
                                <label htmlFor="title">عکس دارو:</label>
                                <input type="file"
                                       name="file"
                                       id="file"
                                       accept="image/*"
                                       onChange={e => {
                                           if (e.target.files) {
                                               this.getBase64(e.target.files[0]);
                                               this.setState({file: e.target.files[0]})
                                           }
                                       }}/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-control">
                                <button type="button" onClick={() => this.save()}>ثبت دارو</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="list">
                    {articles.length > 0 ?
                        <div className="row justify-content-center">
                            {articles.map((ar, i) =>
                                <div key={"ar" + i} className="col-xl-3 col-lg-4 col-md-6">
                                    <article>
                                        <div className="image">
                                            <img src={ar.image} alt=""/>
                                        </div>
                                        <p className="title"><Link to={`/article/${ar.key}`}>{ar.title}</Link></p>
                                        <div className="row justify-content-between">
                                            <div className="col-auto">
                                                <Icon i="edit" className="edit-icon"
                                                      onClick={() => this.props.navigate(`/article/edit/${ar.key}`)}/>
                                            </div>
                                            <div className="col-auto">

                                                <Icon i="trash" className="delete-icon"
                                                      onDoubleClick={() => this.delete(ar.key)}/>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            )
                            }

                        </div>
                        :
                        <div className="no-item">موردی نیست!</div>}
                </div>
            </main>
            <Footer/>
        </>);
    }
}

export default gpn(Articles);