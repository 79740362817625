import React, {Component} from 'react';
import {gpn} from "../GPN"
import Logo from "../asssets/images/logo.png";
import {Link} from "react-router-dom";

class Header extends Component {

    state = {};

    render() {
        const {title} = this.props;
        return (
            <header>
                <div className="row">
                    <div className="col-auto">
                        <div className="logo">
                            <img src={Logo} alt="" onClick={() => {
                                try {
                                    document.location.href = "/";
                                } catch (err) {
                                }
                            }}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <h1>دارویاب&nbsp;|&nbsp;{title}</h1>
                            </div>
                            <div className="col-auto">
                                <nav>
                                    <Link to="/">خانه</Link>
                                    <Link to="/articles">ثبت دارو</Link>
                                    <Link to="/traditional">طب سنتی</Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default gpn(Header);