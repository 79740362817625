import React, {Component} from 'react';
import {gpn} from "../GPN"
import Header from "../sections/Header";
import Footer from "../sections/Footer";

class Article extends Component {

    state = {
        title: "",
        content: "",
        image: "",
        key: ""
    };

    componentDidMount() {
        try {
            const {title, content, image, key} = JSON.parse(localStorage.getItem(this.props.params.key))
            this.setState({
                title,
                content,
                image,
                key
            });
        } catch (err) {
            this.props.navigate("/articles")
        }
    }

    render() {
        const {title, content, image} = this.state;
        return (
            <>
                <Header title={title}/>
                <main className="article">
                    <div className="image">
                        <img src={image} alt=""/>
                        <h1>{title}</h1>
                        <pre>{content}</pre>
                    </div>
                </main>
                <Footer/>
            </>
        );
    }
}

export default gpn(Article);