import React, {Component} from 'react';
import {gpn} from "./GPN"
import {Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import Articles from "./components/Articles";
import Article from "./components/Article";
import EditArticle from "./components/EditArticle";
import Traditional from "./components/Traditional";

class App extends Component {

    render() {
        return (
            <>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/articles" element={<Articles/>}/>
                    <Route path="/article/:key" element={<Article/>}/>
                    <Route path="/article/edit/:key" element={<EditArticle/>}/>
                    <Route path="/traditional" element={<Traditional/>}/>
                </Routes>
            </>
        );
    }
}

export default gpn(App);