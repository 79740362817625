import React, {Component} from 'react';
import {gpn} from "../GPN"
import Header from "../sections/Header";
import Icon from "../sections/Icon";
import axios from "axios";
import config from "../config";
import article from "./Article";

import NoImage from "../asssets/images/no-image.svg";

class Traditional extends Component {

    state = {
        step: "loading",
        search: "میگرن",
        posts: [],
        info: null,

        modal: false,
        modalLoading: true
    };

    constructor() {
        super();
        this.SearchRef = React.createRef();
    }

    componentDidMount() {
        document.title = "دارویاب | طب سنتی";
        this.search();
    }

    search() {
        const {search} = this.state;
        this.setState({
            posts: [],
            step: "loading"
        })
        axios.get(`https://api3.haji-api.ir/majid/fun/tebesonati?action=posts&s=${search}&license=${config.license}`)
            .then(r => {
                this.setState({
                    posts: r.data.result,
                    step: "list"
                })
            })
            .catch(err => {
                setTimeout(() => {
                    this.search();
                }, 5000)
            });
    }

    info(id) {
        this.setState({
            modal: true,
            modalLoading: true
        });
        axios.get(`https://api3.haji-api.ir/majid/fun/tebesonati?action=info&id=${id}&license=${config.license}`)
            .then(r => {
                this.setState({
                    info: r.data.result,
                    modalLoading: false
                })
            })
            .catch(err => {
                setTimeout(() => {
                    this.info(id)
                }, 5000)
            });
    }

    render() {
        const {search, posts, step, modal, modalLoading, info} = this.state;
        return (
            <>
                {modal ?
                    <div className="modal">
                        <div className="card">
                            {
                                modalLoading ?
                                    <section className="loading">
                                        <div className="row justify-content-center">
                                            <div className="col-auto">
                                                <div className="loader"></div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="loading-text">در حال دریافت توضیحات ...</div>
                                            </div>
                                        </div>
                                    </section>
                                    :
                                    <>
                                        <div className="head">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <span className="close" onClick={() => {
                                                        this.setState({
                                                            modal: false,
                                                            modalLoading: true
                                                        })
                                                    }}>بستن</span>
                                                </div>
                                                <div className="col">
                                                    <div className="title">
                                                        {info.title}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="body">
                                            <div className="image">
                                                <img src={info.image ? info.image : NoImage} alt=""/>
                                            </div>

                                            <div className="content"
                                                 dangerouslySetInnerHTML={{__html: info.htmlContent}}></div>
                                        </div>
                                    </>
                            }

                        </div>
                    </div> : null
                }
                <Header title="طب سنتی"/>
                <main className="traditional">

                    <br/>
                    <div className="row justify-content-center">

                        <div className="col-xl-4 col-lg-5 col-md-6">
                            <form onSubmit={e => {
                                e.preventDefault();
                                this.search()
                            }} autoComplete="off">
                                <section className="search">

                                    <input type="text"
                                           ref={this.SearchRef}
                                           name="search"
                                           id="search"
                                           value={search}
                                           onChange={(e) => {
                                               const {name, value} = e.target;
                                               this.setState({[name]: value})
                                           }}
                                           placeholder="جستجو در مقالات ..."/>

                                    <Icon i="search" className="search-icon" solid onClick={() => {
                                        this.SearchRef.current.focus();
                                        this.search()
                                    }}/>
                                </section>
                            </form>

                        </div>
                    </div>

                    <br/>
                    {
                        step === "loading" ?
                            <section className="loading">
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <div className="loader"></div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="loading-text">در حال جستجوی مقاله {search} ...</div>
                                    </div>
                                </div>
                            </section>
                            :
                            <div className="list">
                                <div className="row">
                                    {
                                        posts.map((p, i) =>
                                            <div key={"ar" + i} className="col-xl-3 col-lg-4 col-md-6">
                                                <article onClick={() => {
                                                    this.info(p.id);
                                                }}>
                                                    <div className="image">
                                                        <img src={p.image ? p.image : NoImage} alt=""/>
                                                    </div>
                                                    <p className="title">{p.title.replace("&#8211;", "")}</p>
                                                    <p className="summary">{p.summary}</p>
                                                </article>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                    }

                </main>
            </>
        );
    }
}

export default gpn(Traditional);