import React, {Component} from 'react';
import {gpn} from "../GPN"

class Footer extends Component {

    state = {};

    render() {
        return (
            <footer>
                <p>طراحی شده با&nbsp;<span className="love">❤️</span>&nbsp;توسط&nbsp;<span className="by">سیده آذر طاهریان</span>
                </p>
            </footer>
        );
    }
}

export default gpn(Footer);