import React, {Component} from 'react';
import {gpn} from "../GPN"
import Header from "../sections/Header";
import Footer from "../sections/Footer";

class EditArticle extends Component {

    state = {
        title: "",
        content: "",
        image: "",
        editImage: null,
        key: ""
    };

    componentDidMount() {
        this.getArticle();
    }

    getArticle() {
        try {
            const {title, content, image, key} = JSON.parse(localStorage.getItem(this.props.params.key))
            this.setState({
                title,
                content,
                image,
                key
            });
        } catch (err) {
            this.props.navigate("/articles")
        }
    }

    getBase64(file) {
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                resolve("")
            };
        })

    }

    save() {
        const {title, content, image, editImage, key} = this.state;
        if (title !== "" && content !== "") {
            if (editImage) {
                this.getBase64(editImage)
                    .then(b64 => {
                        localStorage.setItem(key, JSON.stringify({
                            title,
                            image: b64,
                            content,
                            key
                        }))
                    });
            } else {
                localStorage.setItem(key, JSON.stringify({
                    title,
                    image,
                    content,
                    key
                }));
            }

            this.getArticle();
            document.location.href = "/articles";
        } else {
            alert("عنوان یا توضیحات مقاله وارد نشده است!")
        }
    }

    render() {
        const {title, content, image, editImage} = this.state;
        return (
            <>
                <Header/>
                <main className="edit-article">
                    <form
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault()
                            this.save();
                        }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-control">
                                    <label htmlFor="title">عنوان:</label>
                                    <input type="text"
                                           name="title"
                                           id="title"
                                           value={title}
                                           onChange={e => {
                                               const {name, value} = e.target;
                                               this.setState({[name]: value});
                                           }}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="image">
                                            <img src={editImage ? URL.createObjectURL(editImage) : image} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-control">
                                            <label htmlFor="editImage">عکس:</label>
                                            <input type="file"
                                                   name="editImage"
                                                   id="editImage"
                                                   onChange={e => {
                                                       if (e.target.files) {
                                                           this.setState({editImage: e.target.files[0]})
                                                       }
                                                   }}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="form-control">
                            <label htmlFor="content">توضیحات:</label>
                            <textarea
                                name="content"
                                rows={5}
                                id="content"
                                value={content}
                                onChange={e => {
                                    const {name, value} = e.target;
                                    this.setState({[name]: value});
                                }}/>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-control">
                                    <button type="submit">ذخیره</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </main>
                <Footer/>
            </>
        );
    }
}

export default gpn(EditArticle);